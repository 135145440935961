import service from "@/api/service";
import { GetRequest, RegisterRequest } from "@/api/booking-setting/request";
import { GetResponse, RegisterResponse } from "@/api/booking-setting/response";
import { CommonResponse } from "@/api/response";

/**
 * 予約情報取得APIをコールします。
 *
 * @param getRequest 予約情報取得のリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-booking-setting", getRequest);
  return response.data as GetResponse;
}

/**
 * 予約情報登録・編集APIをコールします。
 *
 * @param registerRequest 予約情報取得のリクエストボディ
 * @return RegisterRequest
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post(
    "/regist-booking-setting",
    registerRequest
  );
  return response.data as RegisterResponse;
}
