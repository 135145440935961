import { Component, Prop, Mixins } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import BookingDateGet from "@/store/booking-date/get";
import BookingApprove from "@/store/booking/approve";
import { BookingSettingRequest } from "@/api/booking-setting/request";
import BookingSettingGetAPI from "@/store/booking-setting/get";
import { GetRequest } from "@/api/booking-date/request";
import { ApproveRequest } from "@/api/booking/request";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import Loading from "@/store/loading";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDatePicker }
})
export default class ConfirmEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  id!: string;
  @Prop({ type: String, required: true })
  shopId!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "予約";
  headingSub = "Booking";
  breadCrumbs = [
    { text: "予約", disabled: true },
    { text: "店舗一覧", disabled: false, to: { name: "booking" } },
    { text: "確認", disabled: false, to: this.confirmPageTo },
    { text: "編集", disabled: true }
  ];

  // 予約受付時間（時）のselect
  hours = [...Array(24).keys()].map(n => {
    const val = ("00" + n).slice(-2);
    return { text: val, value: val };
  });

  // 予約受付時間（分）のselect
  minutes = [...Array(4).keys()].map(n => {
    const val = ("00" + n * 15).slice(-2);
    return { text: val, value: val };
  });

  // ------------
  // 変動値
  // ------------
  inputData = {
    date: null as string | null,
    hour: null as string | null,
    minute: null as string | null
  };

  /**
   * カレンダー非活性日設定
   */
  disableDates = {};

  // ------------

  get loading() {
    return Loading.isLoading;
  }

  /**
   * 予約確認画面のパスを返す
   */
  get confirmPageTo() {
    return { name: "booking-confirm", params: { shopId: this.shopId } };
  }

  /**
   * 予約情報の取得処理
   */
  async getSetting(): Promise<boolean> {
    if (BookingSettingGetAPI.isSuccess) {
      await this.setDisableDates();
      return true;
    }

    const request = this.createGetRequest();
    await BookingSettingGetAPI.get(request);
    if (!BookingSettingGetAPI.isSuccess) {
      await Flash.setErrorNow({
        message: BookingSettingGetAPI.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    await this.setDisableDates();
  }

  /**
   * dataから予約情報取得用のリクエストを作成する
   */
  private createGetRequest() {
    return { id: this.shopId } as BookingSettingRequest;
  }

  /**
   * createdライフサイクルフック（VDataTableコンポーネントに渡す）
   */
  async created() {
    await this.getSetting();
    await this.fetchBookingDate();
    this.syncInputParams();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await BookingDateGet.clearResponse();
    await BookingApprove.clearResponse();
  }

  /**
   * DatePickerのdateが変わった際のコールバック
   *
   * @param date 日にち
   */
  dateChangeCallback(date: string | null) {
    this.inputData.date = date;
  }

  /**
   * 承認ボタンが謳歌された時のコールバック
   */
  async approveCallback() {
    await Flash.clear();
    await this.approve();
  }

  /**
   * 予約確定日時情報を取得する
   */
  async fetchBookingDate(): Promise<boolean> {
    if (BookingDateGet.isSuccess) {
      return true;
    }

    let request = { id: Number(this.id) } as GetRequest;
    await BookingDateGet.Get(request);
    if (!BookingDateGet.isSuccess) {
      await Flash.setErrorNow({
        message: BookingDateGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    return BookingDateGet.isSuccess;
  }

  /**
   * 予約確定日時情報を更新する
   */
  async approve() {
    if (!BookingDateGet.isSuccess) {
      return;
    }

    const dateTime = `${this.inputData.date} ${this.inputData.hour}:${this.inputData.minute}:00`;
    let request = { id: Number(this.id), date: dateTime } as ApproveRequest;
    await BookingApprove.approve(request);
    if (BookingApprove.isSuccess) {
      await this.redirectWithSuccessAlert(
        "予約確定日時を変更しました。",
        `/booking/${this.shopId}/confirm`
      );
    } else {
      await Flash.setErrorNow({
        message: BookingApprove.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * 非表示日設定セット
   * @return [description]
   */
  async setDisableDates() {
    // カレンダーにて非活性にする日付設定をセット
    if (BookingSettingGetAPI.getIsHolidayDate) {
      let day;
      this.disableDates.dates = BookingSettingGetAPI.getHolidayDates.map(function (val, i) {
        day = new Date(val.date);
        return day.getFullYear()  + '-' + day.getMonth()  + '-' + day.getDate();
      });
    }
    if (BookingSettingGetAPI.getIsHolidayDayweek) {
      this.disableDates.weeks = BookingSettingGetAPI.getHolidayDayweeks.map(function (val, i) {
        return val.dayweek;
      });
    }
  }

  /**
   * APIのレスポンスをdataにsyncする
   */
  private syncInputParams() {
    this.inputData.date = BookingDateGet.getDate;
    var timeData = BookingDateGet.getTime;
    if(timeData===null) {
      this.inputData.hour = '00';
      this.inputData.minute = '00';
    } else {
      const times = timeData.split(":");
      this.inputData.hour = times[0];
      this.inputData.minute = times[1];
    }
  }
}
