import service from "@/api/service";
import { GetRequest } from "@/api/booking-date/request";
import { GetResponse } from "@/api/booking-date/response";

/**
 * 予約受付日時情報取得APIをコールします。
 *
 * @param getRequest 予約受付日時情報取得のリクエストボディ
 * @return GetRequest
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-booking-date", getRequest);
  return response.data as GetResponse;
}
