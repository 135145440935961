import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { GetRequest } from "@/api/booking-date/request";
import { GetResponse } from "@/api/booking-date/response";
import * as BookingDateAPI from "@/api/booking-date";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "booking-date/get";

/**
 * 予約受付日時情報取得API（/get-booking-date）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getId() {
    return this.getResponse.results.id;
  }

  get getDate() {
    return this.getResponse.results.date;
  }

  get getTime() {
    return this.getResponse.results.time;
  }

  // MutationActions
  @MutationAction
  async Get(gerRequest: GetRequest) {
    const getResponse = await BookingDateAPI.get(gerRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
