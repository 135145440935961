import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse } from "@/api/booking-setting/response";
import { GetRequest } from "@/api/booking-setting/request";
import * as BookingSettingAPI from "@/api/booking-setting";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "booking-setting/get";

/**
 * 予約情報取得API（/get-booking-setting）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getId() {
    return this.getResponse.results ? this.getResponse.results.id : null;
  }

  get getIsAvailable() {
    if (this.getResponse.results) {
      return this.getResponse.results.isAvailable
        ? this.getResponse.results.isAvailable
        : 0;
    } else {
      return 0;
    }
  }

  get getIsAttentionForTemporaryBooking() {
    return this.getResponse.results
      ? this.getResponse.results.isAttentionForTemporaryBooking
      : 0;
  }

  get getIsMenu() {
    return this.getResponse.results ? this.getResponse.results.isMenu : 0;
  }

  get getIsNumber() {
    return this.getResponse.results ? this.getResponse.results.isNumber : 0;
  }

  get getIsHolidayDayweek() {
    return this.getResponse.results
      ? this.getResponse.results.isHolidayDayweek
      : 0;
  }

  get getIsHolidayDate() {
    return this.getResponse.results
      ? this.getResponse.results.isHolidayDate
      : 0;
  }

  get getIsAcceptTime() {
    return this.getResponse.results ? this.getResponse.results.isAcceptTime : 0;
  }

  get getIsAcceptTimeInterval() {
    return this.getResponse.results
      ? this.getResponse.results.isAcceptTimeInterval
      : 0;
  }

  get getIsLastAcceptTime() {
    return this.getResponse.results
      ? this.getResponse.results.isLastAcceptTime
      : 0;
  }

  get getIsAttention() {
    return this.getResponse.results ? this.getResponse.results.isAttention : 0;
  }

  get getAttendionForTemporaryBooking() {
    return this.getResponse.results
      ? this.getResponse.results.attentionForTemporaryBooking
      : null;
  }

  get getMenuTitle() {
    return this.getResponse.results ? this.getResponse.results.menuTitle : null;
  }

  get getNumberMin() {
    return this.getResponse.results ? this.getResponse.results.numberMin : null;
  }

  get getNumberMax() {
    return this.getResponse.results ? this.getResponse.results.numberMax : null;
  }

  get getAcceptStartTimeHour() {
    return this.getResponse.results
      ? this.getResponse.results.acceptStartTimeHour
      : null;
  }

  get getAcceptStartTimeMin() {
    return this.getResponse.results
      ? this.getResponse.results.acceptStartTimeMin
      : null;
  }

  get getAcceptEndTimeHour() {
    return this.getResponse.results
      ? this.getResponse.results.acceptEndTimeHour
      : null;
  }

  get getAcceptEndTimeMin() {
    return this.getResponse.results
      ? this.getResponse.results.acceptEndTimeMin
      : null;
  }

  get getAcceptTimeInterval() {
    return this.getResponse.results
      ? this.getResponse.results.acceptTimeInterval
      : null;
  }

  get getLastAcceptTime() {
    return this.getResponse.results
      ? this.getResponse.results.lastAcceptTime
      : null;
  }

  get getAttention() {
    return this.getResponse.results ? this.getResponse.results.attention : null;
  }

  get getMailForUser() {
    return this.getResponse.results
      ? this.getResponse.results.mailForUser
      : null;
  }

  get getPhoneForUser() {
    return this.getResponse.results
      ? this.getResponse.results.phoneForUser
      : null;
  }

  get getMails() {
    if (this.getResponse.results) {
      return this.getResponse.results.mail ? this.getResponse.results.mail : [];
    } else {
      return [];
    }
  }

  get getMenus() {
    if (this.getResponse.results) {
      return this.getResponse.results.menu ? this.getResponse.results.menu : [];
    } else {
      return [];
    }
  }

  get getHolidayDayweeks() {
    if (this.getResponse.results) {
      return this.getResponse.results.holidayDayweek
        ? this.getResponse.results.holidayDayweek
        : [];
    } else {
      return [];
    }
  }

  get getHolidayDates() {
    if (this.getResponse.results) {
      return this.getResponse.results.holidayDate
        ? this.getResponse.results.holidayDate
        : [];
    } else {
      return [];
    }
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const getResponse = await BookingSettingAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clear() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
